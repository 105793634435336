import React from 'react';
import SEO from 'react-seo-component';
import Layout from '~layouts/mainLayout';
import WelcomeSection from '~components/welcome';
import ProjectsSection from '~components/projects';
import MusicSection from '~components/music';
import BlogSection from '~components/blog';
import { useSiteMetadata } from '~hooks/useSiteMetadata';
import TestimonialsSection from '~components/testimonials';
import IntroCase from '~components/introCase';
import CaseBody from "~components/caseBody";
import metavader from "../assets/images/metavaders.png"
import { FaArrowRight } from 'react-icons/fa';
import clientWork2 from "../assets/images/clientwork2.png";
import Fade from "react-reveal/Fade";

export default function Home() {
  const {
    title,
    titleTemplate,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();
  return (
    <>
      <SEO
        title={title}
        titleTemplate={titleTemplate}
        titleSeparator="|"
        description={description || 'Welcome to my’'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <Layout>
        <IntroCase title="AM" subtitle="Creating a decision-tree based questionnaire to provide recommendation for investments opportunities.
" />
        <section className="mb-24">
     
       
     

        <div class="flex flex-wrap text-center justify-center ">
        <Fade bottom delays={700}>

       
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-amber mb-3">
      Challenge
    </h2>
    <p className="text-secondary prose-lg mb-6">
AM is a software company based in Germany hiring me on a contract basis when they want something of quality and delivered fast. This new project was for a client of theirs who wanted to build a recommendation system for tailored investements plans. One of the slight complexity was to build a decision tree algorithm which could be easily updated by the client. The other one was not resorting to bring ML libraries as AM wanted React/Javascript.
  </p>
   </Fade>
      </div>
    
      <div class="container p-4 mb-10 lg:20 lg:px-20 mx-auto text-center ">
		<div class="relative w-full my-4 mr-auto rounded-2xl ">
			<img alt="Card" src={clientWork2} class="max-w-full rounded-lg "/>
        </div>

        <div class="col-span-12 lg:col-span-8">
                            <div class="inline-block rounded-full text-white 
                                bg-red-400 hover:bg-red-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                                React / Node.JS
                            </div>
                            <div class="inline-block rounded-full text-white 
                                bg-yellow-400 hover:bg-yellow-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              AWS 
                            </div>
                            <div class="inline-block rounded-full text-white 
                                bg-green-400 hover:bg-green-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                                Node-Sass - Tailwind
                            </div>
                            <div class="inline-block rounded-full text-white 
                                bg-blue-400 hover:bg-blue-500 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">
                              Javascript
                            </div>
                           
                        </div>
  </div>
 

      <div class="flex flex-wrap text-center justify-center mb-20">
      <Fade bottom delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-green-500 mb-3">
      Solution
    </h2>
    <p className="text-secondary prose-lg mb-6">
    I am a machine learning enthusiast and further improving on the field so by already understanding how decision tree works, this facilitated development. What is a decision tree ? A decision tree is a supervised machine learning algorithm that can be used for both Regression and Classification problem statements. Hence, by remembering that the final output of the Decision Tree is a Tree having Decision nodes and leaf nodes, I managed to reproduce the logic with Javascript without having to create any Machine learning models with Python SciPy.
    <br />
    <br />
    The design was ready on Figma which facilited my job to just transforming the design to pixel perfect code. 
The outcome is working with the precision of a Swiss watch and a UI which match that last statement.
</p>
    </Fade>
      </div>
      <a href="https://invest-o-mat.villiam.eu" target="_blank">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Visit</span> <span><FaArrowRight /></span> 
              </button>
        </a>
    
    </section>
      </Layout>
    </>
  );
}
